import React from "react"
import Layout from "../components/layout"
import ThanksInformation from "../components/thanks_information"
import ManWithLetter from "../images/svg/man_with_letter.svg"

export default function NotFound() {
  return (
    <Layout showButtons={false}>
      <div className="flex flex-row px-4 pt-20 mx-auto md:space-x-12 md:max-w-screen-md lg:max-w-screen-md">
        <ManWithLetter className="hidden w-80 h-80 md:block" alt=""/>
        <div className="w-full md:w-2/3" >
        <ThanksInformation />
        </div>
      </div>
    </Layout>
  )
}
