import React from "react"
import { useTranslation } from "react-i18next"
import ProgressButton from "./button"
import Socials from "./socials"
import { navigate } from "gatsby"

export default function ThanksInformation(props) {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col w-full md:w-full">
      <div
        className={`inline-block rounded-lg text-left overflow-hidden shadow-xl transform transition-all`}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="flex flex-col py-6 bg-white">
          <h2 className="text-2xl">
            {t("thanksTitleFirstPart")}
            <span className="font-bold">{t("thanksTitleSecondPart")}</span>
          </h2>
          <h3 className="pt-4 pb-6 text-neutral20">{t("thanksSubtitle")}</h3>
          <Socials />
          <div className="mt-8">
            <ProgressButton onClick={() => navigate("/")}>
              <span className="block px-6 py-3 uppercase">
                {t("buttonBack")}
              </span>
            </ProgressButton>
          </div>
        </div>
      </div>
    </div>
  )
}
